'use strict';

angular.module('informaApp')
    .directive('infQlsPotsPopup', function (BiomedtrackerUrlBuilder, ConstantsSvc, OmnitureSvc, DrugLevelPopupHelper) {
        return {
            restrict: 'E',
            templateUrl: 'directives/inf-qlsPotsPopup/template.ptl.html',
            scope: {
                options: "=",
                exportSource: "=",
                qlsInfo: "=",
                poaData: "=",
                potsData: "=",
                showingPots: "="
            },
            getLinks: function (source) {
                if (!source) {
                    return
                }

                source.drugLink = BiomedtrackerUrlBuilder.buildDrugUrl(source.drugId)
                source.indicationLink = BiomedtrackerUrlBuilder.buildIndicationUrl(source.indicationId);
                source.companyLink = BiomedtrackerUrlBuilder.buildCompanyUrl(source.companyId);
            },
            alignHeaderHeights: function (element) {
                var row = element.find('.modal-body .table-header .table-row');

                var cells = row.find('.cell-content');

                var source = cells.toArray().map(function (x) {
                    return $(x).height();
                });

                var max = _.max(source);

                cells.height(max);
            },
            addPaddingInHeader: function (element) {
                var tableBody = element.find('.modal-body .table-body');

                if (tableBody[0].scrollHeight > tableBody.height()) {
                    element.find('.modal-body .table-header .table-row').css("padding-right", "15px");
                    element.find('.modal-body .table-body .table-row .cell-container:last-child').css("border-right", "none");
                } else {
                    element.find('.modal-body .table-header .table-row').css("padding-right", "");
                    element.find('.modal-body .table-body .table-row .cell-container:last-child').css("border-right", "");
                }
            },
            resizeActiveBar: function (element) {
                var bar = element.find('.active-bar');
                element.find('.active-bar').show();
                bar.offset({left: 1000});

                var tableBody = element.find(".modal-body .table-body");
                var height = tableBody[0].clientHeight;
                bar.height(height);
                bar.width(1000);
            },
            hidePhaseColumnSelection: (element) => {
                element.find('.active-bar').hide();
            },
            setUpOptions: function (scope, element) {
                var _this = this;

                if (scope.options == null) {
                    return;
                }

                scope.options.show = function () {
                    var modal = element.find(".qls-pots-popup");
                    modal.modal({backdrop: 'static', keyboard: false});
                    scope.drawing = true;

                    _this.resetPopup(scope, element);

                    modal.on("shown.bs.modal", function fn() {                       
                        _this.getLinks(scope.qlsInfo);
                        
                        _this.resetPopup(scope, element);

                        modal.off("shown.bs.modal", fn);
                    });

                    modal.on("hidden.bs.modal", function fn() {
                        scope.source = null;

                        modal.off("hidden.bs.modal", fn);
                    });
                };

                scope.options.hide = function () {
                    var modal = element.find(".qls-pots-popup");
                    modal.modal("hide");
                }
            },
            resetPopup: (scope, element) => {
                element.find('.modal-content').css('width', '');
                element.find('.modal-content').css('height', '');
                element.find('.modal-content').css('left', '');
                element.find('.modal-content').css('top', '');
            },
            link: function (scope, element, attr) {
                const originalZoom = 1;

                var _this = this,
                    modal = element.find(".qls-pots-popup");

                function waitWhileTableBodyIsDrawing(callback) {
                    if (element.find(".modal-body .table-body")[0].clientHeight > 0) {
                        callback();
                        return;
                    }

                    setTimeout(function () {
                        waitWhileTableBodyIsDrawing(callback);
                    }, 500);
                }

                const popupClasses = {
                    tableBody: 'table-body',
                    zoomContainer: 'zoom-container',
                    modalHeader: 'modal-header',
                    modalFooter: 'modal-footer',
                    tableHeader: 'table-header',
                    scrollContainer: 'scroll-container'
                };

                scope.potsToggle = scope.showingPots;

                scope.onExitToBiomedtracker = () => {
                    OmnitureSvc.exitToBiomedtracker.trackFromDrugLevelPopup();
                };

                scope.dateFormat = ConstantsSvc.Dates.fullMomentFormat;

                scope.isExporting = false;

                scope.drawEnd = function () {
                    scope.processing = false;

                    waitWhileTableBodyIsDrawing(function () {
                        _this.addPaddingInHeader(element);
                        _this.resizeActiveBar(element);
                        scope.drawing = false;
                        scope.$digest();
                    })
                };

                scope.$watch("options", function () {
                    _this.setUpOptions(scope, element);
                });

                scope.exportExcel = function () {
                    scope.exportSource(scope.qlsInfo.indicationId, scope.qlsInfo.drugId)
                };

                const scrollSize = window.getScrollSize();

                function setSortDirection(sorting) {
                    if (scope.currentSorting === sorting) {
                        scope.sortAsc = !scope.sortAsc;
                    } else {
                        scope.sortAsc = true;
                    }
                }

                function fitTableBodyInModalHeight(zoom) {
                    element.find(`.${popupClasses.tableBody}`).innerHeight(calculateTableBodyHeight(zoom));
                }

                function zoomPopupContentDependsOnWidth(width) {
                    const borderWidth = 1000;
                    const reducedZoom = 0.7;

                    const zoomContainer = element.find(`.${popupClasses.zoomContainer}`);

                    const zoom = width < borderWidth ? reducedZoom : originalZoom;
                    zoomContainer.css({zoom});

                    return zoom;
                }

                function calculateTableBodyHeight(zoom) {
                    const getHeight = (className) => element.find(`.${className}`).outerHeight();

                    const modalHeaderHeight = getHeight(popupClasses.modalHeader);
                    const modalFooterHeight = getHeight(popupClasses.modalFooter);
                    const tableHeaderHeight = getHeight(popupClasses.tableHeader);

                    const scrollContainer = element.find(`.${popupClasses.scrollContainer}`)[0];
                    const scrollHeight = scrollContainer.scrollWidth > scrollContainer.clientWidth ? scrollSize : 0;

                    const zoomMeasurementError = !zoom || zoom === originalZoom ? 0 : 10; //TODO: temp, investigate and remove;

                    return getHeight(popupClasses.zoomContainer) - modalHeaderHeight - modalFooterHeight - tableHeaderHeight - scrollHeight - zoomMeasurementError;
                }

                this.setUpOptions(scope, element);
            }
        }
    });
